<template>
  <div
    class="q-notifications__list fixed column no-wrap q-ma-md items-center q-notifications__list--bottom"
    style="margin-bottom: 80px;"
  >
    <notification
      v-for="item in notifications"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import Notification from './Notification'

export default {
  name: 'Notifications',
  components: {
    Notification
  },
  computed: {
    ...mapGetters([
      'notifications'
    ])
  }
}
</script>
