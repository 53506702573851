<template>
  <div
    v-if="item.mode !== 'sound'"
    class="q-notification row q-mx-none q-mt-md items-stretch q-notification--standard text-white"
    :class="`bg-${colors[item.type]}`"
    style="min-width: 300px;"
  >
    <div class="q-notification__wrapper col relative-position border-radius-inherit row items-center">
      <div class="row justify-content-between full-width card--clickable card--clickable-no-scale">
        <div class="col-10">
          {{ item.message }}
        </div>

        <div class="col-1 row items-center justify-end">
          <q-icon
            name="close"
            @click="removeNotification(item.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'Notification',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      timeout: null,
      delay: 5000,
      colors: {
        success: 'positive',
        warning: 'warning',
        error: 'negative',
        danger: 'negative'
      }
    }
  },
  mounted () {
    this.createSoundEffect(this.item.type)

    this.timeout = setTimeout(() => {
      this.removeNotification(this.item.id)
    }, this.delay)
  },
  unmounted () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  methods: {
    ...mapMutations([
      'removeNotification'
    ]),
    createSoundEffect (type) {
      const oldTypes = {
        error: 'danger'
      }

      const sound = this.$appOptions.notificationsSounds[oldTypes[type] || type] || this.$appOptions.notificationsSounds.default
      const audio = new Audio(sound)

      audio.play()
        .catch(error => {
          console.debug(error.message)
        })
    }
  }
}
</script>
